import {
  COURSE_FOOTBALL,
  COURSE_NOSQL_INTRO,
  COURSE_ADOBE_TUTORIAL,
  COURSE_AI,
  COURSE_AWS_CLOUD1,
  COURSE_AWS_CLOUD2,
  COURSE_BIG_DATA,
  COURSE_CAREER_PLANNING,
  COURSE_CONTENT_WRITING,
  COURSE_DIVING_INTO_CP,
  COURSE_MAGIC,
  COURSE_SOFTSKILLS1,
  COURSE_SOFTSKILLS2,
  COURSE_WEBAUDIT,
  COURSE_PSYCHOLOGY,
  COURSE_MARKETING_3P,
  COURSE_MARKETING_ANALYSIS,
  COURSE_SEM,
  COURSE_DESIGN_THINKING,
  COURSE_STOCK,
  COURSE_OM,
  COURSE_PD,
  COURSE_PUBLIC_RELATION,
  COURSE_IOT,
  COURSE_ETHICAL_HACKING,
  COURSE_GAME_DEV,
  COURSE_CYBER_SECURITY,
  COURSE_TENSOR_FLOW,
  COURSE_LINUX,
  COURSE_CPLUS_ARRAY,
  COURSE_PYTHON_TUTORIAL,
  COURSE_WEB_DEV,
  COURSE_JAVA,
  COURSE__PYGAME,
  COURSE_DATA_SCIENCE,
  COURSE_STATISTICS,
} from "../../../utils/localization";

export const CourseData = [
  {
    img: `${COURSE_FOOTBALL}`,
    title: "Foodball for beginners",
    coachname: "Fc goa",
    category: "Vocational",
    Featured: "True",
  },
  {
    img: `${COURSE_MAGIC}`,
    title: "Magic 101",
    coachname: "Kunal Newar",
    category: "Vocational",
    Featured: "",
  },
  {
    img: `${COURSE_CAREER_PLANNING}`,
    title: "Career Planning - Create your profile and get your dream job",
    coachname: "Louise Moseeline",
    category: "Vocational",
    Featured: "",
  },
  {
    img: `${COURSE_SOFTSKILLS1}`,
    title: "Soft Skills - Setting your attitude to shape your future",
    coachname: "Narayanan S",
    category: "Vocational",
    Featured: "",
  },
  {
    img: `${COURSE_PSYCHOLOGY}`,
    title: "Psychology: Mind and Behaviour",
    coachname: "Harshita Lalchand",
    category: "Vocational",
    Featured: true,
  },
  {
    img: `${COURSE_ADOBE_TUTORIAL}`,
    title: "Graphic Design - Adobe Photoshop Tutorials",
    coachname: "Shanica Roy",
    category: "Vocational",
    Featured: true,
  },
  {
    img: `${COURSE_SOFTSKILLS2}`,
    title: "Soft skills- Leadership and interpersonal skills",
    coachname: "Narayanan S",
    category: "Vocational",
    Featured: "",
  },
  {
    img: `${COURSE_WEBAUDIT}`,
    title: "Website Audit & Google search console",
    coachname: "Ishan Singh",
    category: "Marketing",
    Featured: "",
  },
  {
    img: `${COURSE_MARKETING_3P}`,
    title: "Marketing Management: Product, Price & Distribution",
    coachname: "Miguel Magalhães",
    category: "Marketing",
    Featured: "",
  },
  {
    img: `${COURSE_CONTENT_WRITING}`,
    title: "Content Writing - Personal Branding",
    coachname: "Nishtha Gehija",
    category: "Marketing",
    Featured: true,
  },

  {
    img: `${COURSE_MARKETING_ANALYSIS}`,
    title: "Marketing Management: How to do Market analysis",
    coachname: "Miguel Magalhães",
    category: "Marketing",
    Featured: "",
  },
  {
    img: `${COURSE_SEM}`,
    title: "Search Engine Marketing (SEM)",
    coachname: "Ishan Singh",
    category: "Marketing",
    Featured: "",
  },
  {
    img: `${COURSE_DESIGN_THINKING}`,
    title: "Design Thinking ",
    coachname: "Aruna Shekar",
    category: "Management",
    Featured: "",
  },

  {
    img: `${COURSE_STOCK}`,
    title: "Stock Market and Finance - Understanding the art of stock picking",
    coachname: "Himanshu Raghani",
    category: "Management",
    Featured: "",
  },

  {
    img: `${COURSE_OM}`,
    title: "Operations Management",
    coachname: "Priya Alhuwalia",
    category: "Management",
    Featured: "",
  },

  {
    img: `${COURSE_PD}`,
    title: "Product Development - Product Ideation & Storyboard",
    coachname: "Zahin Hussain",
    category: "Management",
    Featured: true,
  },

  {
    img: `${COURSE_PUBLIC_RELATION}`,
    title: "Public Relations: Career building & Media relations",
    coachname: "Pooja Pathak",
    category: "Management",
    Featured: "",
  },

  {
    img: `${COURSE_IOT}`,
    title:
      "Industrial IoT - Embedded Development - Hardware and Software introduction",
    coachname: "Praveen S",
    category: "Software",
    Featured: "",
  },
  {
    img: `${COURSE_ETHICAL_HACKING}`,
    title: "Ethical Hacking - Information Gathering, Footprinting, and Recon",
    coachname: "Urevesh Thakkar",
    category: "Software",
    Featured: true,
  },
  {
    img: `${COURSE_GAME_DEV}`,
    title: "Game Development - Introduction to 3D World Design",
    coachname: "Imran Khan",
    category: "Software",
    Featured: true,
  },
  {
    img: `${COURSE_CYBER_SECURITY}`,
    title: " Cyber Security - Malwares",
    coachname: "Vishal Dave",
    category: "Software",
    Featured: "",
  },
  {
    img: `${COURSE_AWS_CLOUD1}`,
    title: "AWS Cloud Computing -  Database on AWS and self practice",
    coachname: "Tarun Vakkalagadda",
    category: "Software",
    Featured: "",
  },
  {
    img: `${COURSE_TENSOR_FLOW}`,
    title: "Introduction to Tensorflow ",
    coachname: "Deepali Vora",
    category: "Software",
    Featured: "",
  },
  {
    img: `${COURSE_LINUX}`,
    title: "Linux - Baisc Commands for Beginners",
    coachname: "Vishal Dave",
    category: "Software",
    Featured: "",
  },

  {
    img: `${COURSE_AI}`,
    title: "Artificial Intelligence - Introduction to Pandas",
    coachname: "Tusha Dhyani",
    category: "Coding",
    Featured: "",
  },

  {
    img: `${COURSE_CPLUS_ARRAY}`,
    title: "C++ Arrays, Structures and Functions",
    coachname: "Rohan Mathur",
    category: "Coding",
    Featured: "",
  },

  {
    img: `${COURSE_PYTHON_TUTORIAL}`,
    title: "Python Tutorials with introduction to Turtle & Twilio",
    coachname: "Manasa Kota",
    category: "Coding",
    Featured: true,
  },

  {
    img: `${COURSE_DIVING_INTO_CP}`,
    title: "C++ - Diving into C",
    coachname: "Rohan Mathur",
    category: "Coding",
    Featured: "",
  },

  {
    img: `${COURSE_WEB_DEV}`,
    title: "Web Development: Javascript",
    coachname: "Anamika Pal",
    category: "Coding",
    Featured: "",
  },

  {
    img: `${COURSE_JAVA}`,
    title: "Introduction to Java - Full guide",
    coachname: "Manasa Kota",
    category: "Coding",
    Featured: true,
  },

  {
    img: `${COURSE__PYGAME}`,
    title: "Pygame Tutorial- A gaming module in Python",
    coachname: "Manasa Kota",
    category: "Data",
    Featured: "",
  },
  {
    img: `${COURSE_NOSQL_INTRO}`,
    title: "Big Data -  Introduction NoSQL Databases",
    coachname: "Sanjoy Chowdhary",
    category: "Data",
    Featured: "",
  },
  {
    img: `${COURSE_AWS_CLOUD2}`,
    title: "AWS Cloud Computing -  Database on AWS and self practice",
    coachname: "Tarun Vakkalagadda",
    category: "Data",
    Featured: "",
  },
  {
    img: `${COURSE_DATA_SCIENCE}`,
    title: "Data Science: Introduction",
    coachname: "Nikanksha Jain",
    category: "Data",
    Featured: "",
  },
  {
    img: `${COURSE_STATISTICS}`,
    title: "Statistics",
    coachname: "Shubhra Gupta",
    category: "Data",
    Featured: true,
  },
  {
    img: `${COURSE_BIG_DATA}`,
    title: "Big Data - Hadoop as a platform for managing Big Data",
    coachname: "Sanjoy Chowdhary",
    category: "Data",
    Featured: true,
  },
];
