import React, { useState } from "react";
import { useSelector } from "react-redux";
import { LMS_PLATFORM_OUT_LINK } from "../../utils/localization";

const FloatButton = ({ setOpenAuthModal }) => {
  const [show, setshow] = useState(false);
  const [mshow, msetshow] = useState(false);

  const user = useSelector((state) => state.user);

  const isBrowser = typeof window !== "undefined" && window;

  const scrolling = () => {
    if (isBrowser.scrollY > 700 && isBrowser.screen.width > 1200) {
      return setshow(true);
    } else if (isBrowser.scrollY > 700 && isBrowser.screen.width < 776) {
      return msetshow(true);
    } else {
      setshow(false);
      msetshow(false);
    }
  };

  if (typeof window !== "undefined") {
    window.addEventListener("scroll", scrolling);
  }

  const naviateToDashboard = () => {
    window.open(LMS_PLATFORM_OUT_LINK, "_blank");
  };

  return (
    <div
      className={
        mshow ? "float-button-container show" : "float-button-container"
      }
    >
      <div className={show || mshow ? "float-btn show" : "float-btn"}>
        {user?.isUserLoggedIn ? (
          <div
            onClick={() => {
              naviateToDashboard();
            }}
          >
            ENROLL FOR FREE
          </div>
        ) : (
          <div
            onClick={() => {
              setOpenAuthModal(true);
            }}
          >
            ENROLL FOR FREE{" "}
          </div>
        )}
      </div>
    </div>
  );
};

export default FloatButton;
