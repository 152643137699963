import {
  ANTONIO_IMAGE_PATH,
  FC_GOA_LOGO_IMAGE_PATH,
  GAVIN_IMAGE_PATH,
  SHANE_IMAGE_PATH,
  ROBERT_IMAGE_PATH,
  DIGITAL_LOGO_IMAGE_PATH,
} from "../../../utils/localization";

export const CoachData = [
  {
    img: `${ANTONIO_IMAGE_PATH}`,
    Coachname: "Antonio Prestly Pereira",

    title: "U15 Head Coach, FC Goa",

    logo: `${FC_GOA_LOGO_IMAGE_PATH}`,
  },
  {
    img: `${GAVIN_IMAGE_PATH}`,
    Coachname: "Gavin Araujo",

    title: "U18 Head Coach, FC Goa",

    logo: `${FC_GOA_LOGO_IMAGE_PATH}`,
  },
  {
    img: `${SHANE_IMAGE_PATH}`,
    Coachname: "Shane Temudo",

    title: "U18 Head Coach, FC Goa",

    logo: `${FC_GOA_LOGO_IMAGE_PATH}`,
  },
  {
    img: `${ROBERT_IMAGE_PATH}`,
    Coachname: "Robert Friedrich",

    title: "Athletics & Physical Coordinator, RB Leipzig",

    logo: `${DIGITAL_LOGO_IMAGE_PATH}`,
  },
];
