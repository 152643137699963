import React, { useState } from "react";
import { Helmet } from "react-helmet";
import "../../../components/FcGoaComponents/styles/style.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Hero from "../../../components/FcGoaComponents/Hero";
import PoweredBy from "../../../components/FcGoaComponents/PoweredBy";
import Courses from "../../../components/FcGoaComponents/Courses";
import DividerContainer from "../../../components/FcGoaComponents/DividerContainer";
import Journey from "../../../components/FcGoaComponents/Journey";
import Coaches from "../../../components/FcGoaComponents/Coaches";

import Numbers from "../../../components/FcGoaComponents/Numbers";
import Faq from "../../../components/FcGoaComponents/Faq";

import FloatButton from "../../../components/FcGoaComponents/FloatButton";

import LayoutWithOutPadding from "../../../components/LayoutWithOutPadding";
import AuthModal from "../../../components/AuthModal";
import { LOGIN } from "../../../utils/localization";
import { isFunction } from "lodash";
import { useEffect } from "react";
import { isSSR } from "../../../helpers/global";

export default function SKillCourses() {
  const [initialAuthState, setInitialAuthState] = useState(LOGIN);
  const [openAuthModal, setOpenAuthModal] = useState(false);

  const handleCloseModal = (callback = () => {}) => {
    setOpenAuthModal(false);
    isFunction(callback) && callback();
  };

  !isSSR() && (window.location.href = "/404");

  return (
    <>
      {/* <LayoutWithOutPadding>
        <AuthModal
          key={initialAuthState}
          openModal={openAuthModal}
          initialAuthState={initialAuthState}
          handleCloseModal={handleCloseModal}
        />
        <Helmet>
          <title>India’s Best Free E-Learning Library | Unschool</title>
          <meta
            name="description"
            content="Introducing 'OTT for learning. Explore the E-Learning library with 500+ courses and still counting. Start your 7 days free trial now!"
          />
          <link
            rel="apple-touch-icon"
            sizes="256x256"
            href="https://www.unschool.in/img/apple-touch-icon.png"
          />
          <link
            rel="icon"
            type="image/png"
            href="https://www.unschool.in/img/apple-touch-icon.png"
            sizes="256x256"
          />
        </Helmet>
        <FloatButton setOpenAuthModal={setOpenAuthModal} />
        <Hero setOpenAuthModal={setOpenAuthModal} />
        <Journey />
        <Coaches />
        <DividerContainer setOpenAuthModal={setOpenAuthModal} />
        <Courses />
        <Numbers />
        <PoweredBy />
        <Faq />
      </LayoutWithOutPadding> */}
      <div></div>
    </>
  );
}
